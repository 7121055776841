import { Component } from '@angular/core';
import { LanguageService } from './language.service';
import { Router, NavigationEnd } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(public languageService: LanguageService, private router: Router, private cookieService: CookieService) {}

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });

  }

  categoryDis = 'none';

    categoryItems = [
      { title: 'Candy', font: 'mr-2 fas fa-candy-cane', link: 'candy' }, { title: 'Wine', font: 'mr-2 fas fa-cocktail', link: 'wine' },
      { title: 'Giftings', font: 'mr-2 fas fa-gift', link: 'gifting' }, { title: 'Beverage', font: 'mr-2 fas fa-mug-hot', link: 'drink' },
      { title: 'Snacks', font: 'mr-2 fas fa-cookie-bite', link: 'snack' }, { title: 'Cakes', font: 'mr-2 fas fa-bread-slice', link: 'cake' },
      { title: 'Noodles', font: 'mr-2 fas fa-mortar-pestle', link: 'noodle' },{ title: 'Chocolate', font: 'mr-2 fas fa-bar', link: 'chocolate' }
    ];

  showCategory(state) {
    this.categoryDis = state;
  }

  languageSelection(language) {
    this.languageService.setLanguage(language);
  }


}
