import { Injectable } from '@angular/core';

@Injectable()
export class LanguageService {

  constructor() {
    if(localStorage.getItem("language") == null){
      this.setLanguage('english');
    }
    else{
      this.language = this.setLanguage(localStorage.getItem("language"));
    }
   }

  private language;


  // home page
  home_p1 = 'The Unimpeded and Endless Growth. With a decade of delication in sourcing and distrubuting qualified groceries in the region.';
  home_p2 = "We strive to make poosible our company's goal of introducing a great variety of finest foods and grocery products around the world to our valuable partners and customers.";
  home_button = "LEARN MORE ABOUT US";
  home_snack = "Snack";
  home_drink = "Beverage";
  home_cake = "Cake";
  home_noodle = "Noodle";
  home_candy = "Jelly & Candy";
  home_wine = "Japanese Wine";
  home_gifting = "Gifting";

  // app page
  app_nav = [ { name: 'ABOUT', page: '/about' }, { name: 'THAILAND', page: '' }];
  app_footer = "CONTACT US";
  app_japan = "JAPAN";
  app_thailand =  "THAILAND";
  app_address = "Flat D, 14/F Block 1, Tai Ping Industrial Centre, No.57 Ting Kok Road, Tai Po N.T Hong Kong";
  app_category_type = [
    { title: 'Japan', src:'../assets/japan.png', link: 'candy'}, { title: 'Thailand', src:'../assets/thailand.png', link: 'wine' },
  ];
  app_category = [
    { title: 'Candy', font: 'mr-2 fas fa-candy-cane', link: 'candy' }, { title: 'Wine', font: 'mr-2 fas fa-cocktail', link: 'wine' },
    { title: 'Gifting', font: 'mr-2 fas fa-gift', link: 'gifting' }, { title: 'Beverage', font: 'mr-2 fas fa-mug-hot', link: 'drink' },
    { title: 'Snack', font: 'mr-2 fas fa-cookie-bite', link: 'snack' }, { title: 'Cake', font: 'mr-2 fas fa-bread-slice', link: 'cake' },
    { title: 'Noodle', font: 'mr-2 fas fa-mortar-pestle', link: 'noodle' },{ title: 'Chocolate', font: 'mr-2 fas fa-bars', link: 'chocolate' }
  ];

  // about
  about_about_title = "Our Story";
  about_p1 = "ManTung Trading Company Limited is an international distribution and trading company located in Hong Kong that was founded in 9th October 2007.";
  about_p2 = "Our Hong Kong Port provides a good access for vessels from all over the world and plays a very important role as the gateway to Modern China and we trades a wide range of quality snacks, food and health supplements or health products from Japan.";
  about_p3 = "Our customers include super markets, convenience stores, upmarket department stores plus a good local distribution network of wholesalers, retailers and e-platforms.";
  about_mission_title = "Mission & Vision";
  about_mission = 'We strive to provide a wider range of quality products with emphasis on Daily and Health products through effective marketing strategies and brand recognition and diversify and expand our customer base.';

  //client
  client = "Our Clients";

  setLanguage(language) {

    localStorage.setItem("language", language);

    this.language = localStorage.getItem("language");
    
    // simplified
    if (this.language === 'simplified') {
      // home
      this.home_p1 = "在各地区采购和分销优质产品的精神";
      this.home_p2 = "我们努力使公司的目标成为我们有价值的合作伙伴和客户在世界各地推出各种最好的食品和杂货产品";
      this.home_button = "了 解 更 多";
      this.home_snack = "零食";
      this.home_drink = "饮品";
      this.home_cake = "蛋糕";
      this.home_noodle = "面类";
      this.home_candy = "糖果";
      this.home_wine = "酒类";
      this.home_gifting = "礼盒";
      // app
      this.app_nav = [ { name: '关于', page: '/about' }];
      this.app_footer = "联系我们";
      this.app_japan = '日本';
      this.app_thailand =  "泰国";
      this.app_address = "香港新界大埔汀角路57号太平工业中心一座十四楼D座";
      this.app_category = [
        { title: '糖', font: 'mr-2 fas fa-candy-cane', link: 'candy' }, { title: '酒类', font: 'mr-2 fas fa-cocktail', link: 'wine' },
        { title: '礼盒', font: 'mr-2 fas fa-gift', link: 'gifting' }, { title: '饮品', font: 'mr-2 fas fa-mug-hot', link: 'drink' },
        { title: '零食', font: 'mr-2 fas fa-cookie-bite', link: 'snack' }, { title: '蛋糕', font: 'mr-2 fas fa-bread-slice', link: 'cake' },
        { title: '面类', font: 'mr-2 fas fa-mortar-pestle', link: 'noodle' },{ title: '巧克力', font: 'mr-2 fas fa-bars', link: 'chocolate' }
      ];
      // about
      this.about_about_title = "关于万通";
      this.about_p1 = "万通进出口贸易有限公司于2007年成立，是环球食品出入口商。";
      this.about_p2 = "我们主要的业务是为香港客户提供优质食品，入口贸易，批发及分销，亦于国内及香港建立庞大的客户分销网络。";
      this.about_p3 = "主要进口世界各地各种高级优质美酒和美食，包括零食、饮料、罐头食品、粮油酱汁、酒类等等。";
      this.about_mission_title = "我们的目标";
      this.about_mission = '本公司一直本着 "正货产品，信心保证。服务至上，以客为先" 为经营理念，致力为全港市民提供优质的产品及服务。';

       //client
      //  this.client = "合作伙伴";
    }
    // traditional
    else if (this.language === 'traditional') {
      //client
      // this.client = "合作夥伴";
      
      // home
      this.home_p1 = "在各地區採購和分銷優質產品的精神";
      this.home_p2 = "我們努力使公司的目標成為我們有價值的合作夥伴和客戶在世界各地推出各種最好的食品和雜貨產品";
      this.home_button = "了 解 更 多";
      this.home_snack = "零食";
      this.home_drink = "飲品";
      this.home_cake = "蛋糕";
      this.home_noodle = "面類";
      this.home_candy = "糖果";
      this.home_wine = "酒類";
      this.home_gifting = "禮盒";
      // app
      // this.app_nav = [ { name: '關於', page: '/about' }, { name: '客戶', page: 'client' }];
      this.app_nav = [ { name: '關於', page: '/about' }];
      this.app_footer = "聯繫我們";
      this.app_japan = '日本';
      this.app_thailand =  "泰國";
      this.app_address = "香港新界大埔汀角路57號太平工業中心一座十四樓D座";
      this.app_category = [
        { title: '糖', font: 'mr-2 fas fa-candy-cane', link: 'candy' }, { title: '酒類', font: 'mr-2 fas fa-cocktail', link: 'wine' },
        { title: '禮盒', font: 'mr-2 fas fa-gift', link: 'gifting' }, { title: '飲品', font: 'mr-2 fas fa-mug-hot', link: 'drink' },
        { title: '零食', font: 'mr-2 fas fa-cookie-bite', link: 'snack' }, { title: '蛋糕', font: 'mr-2 fas fa-bread-slice', link: 'cake' },
        { title: '面類', font: 'mr-2 fas fa-mortar-pestle', link: 'noodle' },{ title: '朱古力', font: 'mr-2 fas fa-bars', link: 'chocolate' }
      ];
      // about
      this.about_about_title = "關於萬通";
      this.about_p1 = "萬通進出口貿易有限公司於2007年成立，是環球食品出入口商。";
      this.about_p2 = "我們主要的業務是為香港客戶提供優質食品，入口貿易，批發及分銷，亦於國內及香港建立起龐大的客戶分銷網絡。";
      this.about_p3 = "主要進口世界各地各種高級優質美酒和美食，包括零食、飲料、罐頭食品、糧油醬汁、酒類等等。";
      this.about_mission_title = "我們的目標";
      this.about_mission = '本公司一直本著 "正貨產品，信心保證。服務至上，以客為先" 為經營理念，致力為全港市民提供優質的產品及服務';
    }
    else {
      // app
      // this.app_nav = [ { name: 'ABOUT', page: '/about' }, { name: 'CLIENTS', page: 'client' }];
      this.app_nav = [ { name: 'ABOUT', page: '/about' }];
      this.app_footer = "CONTACT US";
      this.app_japan = "JAPAN";
      this.app_thailand =  "THAILAND";
      this.app_address = "Flat D, 14/F Block 1, Tai Ping Industrial Centre, No.57 Ting Kok Road, Tai Po N.T Hong Kong";
      this.app_category = [
        { title: 'Candy', font: 'mr-2 fas fa-candy-cane', link: 'candy' }, { title: 'Wine', font: 'mr-2 fas fa-cocktail', link: 'wine' },
        { title: 'Gifting', font: 'mr-2 fas fa-gift', link: 'gifting' }, { title: 'Beverage', font: 'mr-2 fas fa-mug-hot', link: 'drink' },
        { title: 'Snack', font: 'mr-2 fas fa-cookie-bite', link: 'snack' }, { title: 'Cake', font: 'mr-2 fas fa-bread-slice', link: 'cake' },
        { title: 'Noodle', font: 'mr-2 fas fa-mortar-pestle', link: 'noodle' },{ title: 'Chocolate', font: 'mr-2 fas fa-bars', link: 'chocolate' }
      ];

      // home
      this.home_p1 = 'The Unimpeded and Endless Growth. With a decade of delication in sourcing and distrubuting qualified groceries in the region.';
      this.home_p2 = "We strive to make poosible our company's goal of introducing a great variety of finest foods and grocery products around the world to our valuable partners and customers.";
      this.home_button = "LEARN MORE ABOUT US";
      this.home_snack = "Snack";
      this.home_drink = "Beverage";
      this.home_cake = "Cake";
      this.home_noodle = "Noodle";
      this.home_candy = "Jelly & Candy";
      this.home_wine = "Japanese Wine";
      this.home_gifting = "Gifting";

      //client
      this.client = "Our Clients";
      
      // about
      this.about_about_title = "Our Story";
      this.about_p1 = "ManTung Trading Company Limited is an international distribution and trading company located in Hong Kong that was founded in 9th October 2007.";
      this.about_p2 = "Our Hong Kong Port provides a good access for vessels from all over the world and plays a very important role as the gateway to Modern China and we trades a wide range of quality snacks, food and health supplements or health products from Japan.";
      this.about_p3 = "Our customers include super markets, convenience stores, upmarket department stores plus a good local distribution network of wholesalers, retailers and e-platforms.";
      this.about_mission_title = "Mission & Vision";
      this.about_mission = 'We strive to provide a wider range of quality products with emphasis on Daily and Health products through effective marketing strategies and brand recognition and diversify and expand our customer base.';
    }
  }

  getLanguage() {
    return this.language;
  }

  public getLocal(){
    return localStorage.getItem('language');
}
}