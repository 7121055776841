import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gifting',
  templateUrl: './gifting.component.html',
  styleUrls: ['./gifting.component.css']
})
export class GiftingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  gifts = [
    { src:'../../assets/products/gifting/1.jpg', title: '', state:'none'},
    { src:'../../assets/products/gifting/2.jpg', title: '', state:'none' },
    { src:'../../assets/products/gifting/3.jpg', title: '', state:'none' },
    { src:'../../assets/products/gifting/4.jpg', title: '', state:'none' },
  ];


}
