import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chocolate',
  templateUrl: './chocolate.component.html',
  styleUrls: ['./chocolate.component.css']
})
export class ChocolateComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  chocolates = [
    { src:'../../assets/products/chocolate/明治五彩蛋巧克力.png', title: '明治五彩蛋巧克力.png', state:'none'},
    { src:'../../assets/products/chocolate/明治五寶巧克力.png', title: '明治五寶巧克力', state:'none'},
    { src:'../../assets/products/chocolate/明治五寶糖.png', title: '明治五寶糖', state:'none'},
    { src:'../../assets/products/chocolate/明治脆豆巧克力草莓.jpg', title: '明治脆豆巧克力草莓', state:'none'},
    { src:'../../assets/products/chocolate/明治脆豆巧克力香蕉.png', title: '明治脆豆巧克力香蕉', state:'none'},
    { src:'../../assets/products/chocolate/明治太空船巧克力四把傘.png', title: '明治太空船巧克力四把傘',  state:'none'},
    { src:'../../assets/products/chocolate/明治bb豆巧克力.png', title: '明治bb豆巧克力', state:'none'},
    { src:'../../assets/products/chocolate/不二家鉛筆巧克力.png', title: '不二家鉛筆巧克力', state:'none'},
    { src:'../../assets/products/chocolate/不二家麵包超人棒棒巧克力.png', title: '不二家麵包超人棒棒巧克力',  state:'none'},
    { src:'../../assets/products/chocolate/不二家麵包超人單棒巧克力.png', title: '不二家麵包超人單棒巧克力',  state:'none'},
    { src:'../../assets/products/chocolate/不二家牛奶妹雙棒巧克力.png', title: '不二家牛奶妹雙棒巧克力',  state:'none'},
    { src:'../../assets/products/chocolate/不二家麵包超人巧克力.png', title: '不二家麵包超人巧克力',  state:'none'},

   
  ];

  mouseIn(state, index){
      this.chocolates[index].state = state;
  }

}
