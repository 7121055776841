import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-drink',
  templateUrl: './drink.component.html',
  styleUrls: ['./drink.component.css']
})
export class DrinkComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  drinks = [
    { src:'../../../../assets/products/beverage/日本可口可樂500ml.png', title: '日本可口可樂', state:'none'},
    { src:'../../../../assets/products/beverage/Ucc混合咖啡185ml.jpg', title: 'Ucc 混合咖啡', state:'none' },
    { src:'../../../../assets/products/beverage/Ucc混合牛奶咖啡185ml.jpg', title: 'Ucc 混合牛奶咖啡', state:'none' },
    { src:'../../../../assets/products/beverage/Ucc混合微糖咖啡185ml.jpg', title: 'Ucc 混合微糖咖啡', state:'none' },
    { src:'../../../../assets/products/beverage/Ucc職人珈琲無糖930ml.jpg', title: 'Ucc 職人珈琲 無糖', state:'none' },
    { src:'../../../../assets/products/beverage/午後紅茶.jpg', title: '午後紅茶',  state:'none' },
    { src:'../../../../assets/products/beverage/午后柠檬茶.jpg', title: 'Kirin 午後檸檬茶',  state:'none' },
    { src:'../../../../assets/products/beverage/Kirin生茶525m.jpg', title: 'Kirin 生茶', state:'none' },
    { src:'../../../../assets/products/beverage/Kirin麒麟世界廚房咸荔枝飲料1500ml.png', title: 'Kirin 麒麟世界廚房咸荔枝飲料', state:'none' },
    { src:'../../../../assets/products/beverage/Pokka蜜桔果粒混合果汁飲料.jpg', title: 'Pokka蜜桔果粒混合果汁飲料', state:'none' },
    { src:'../../../../assets/products/beverage/AsahiWonda極黑咖啡.jpg', title: 'Asahi Wonda 極 黑咖啡', state:'none' },
    { src:'../../../../assets/products/beverage/JA優酪乳酸飲料.jpg', title: 'JA優酪乳酸飲料',  state:'none' },
    { src:'../../../../assets/products/beverage/图片1.png', title: '三得利 緑茶　伊右衛門', state:'none' },
    { src:'../../../../assets/products/beverage/图片2.png', title: 'ITO 伊藤園不二家罐裝白桃汁', state:'none' },
    { src:'../../../../assets/products/beverage/图片3.png', title: 'SUN伊右衛門 玄米茶', state:'none' },
    { src:'../../../../assets/products/beverage/图片4.png', title: '天然水550g', state:'none' },
    { src:'../../../../assets/products/beverage/图片5.png', title: '天然水2L', state:'none' },
    { src:'../../../../assets/products/beverage/图片6.png', title: 'Itoen 放鬆茉莉花茶', state:'none' },
    { src:'../../../../assets/products/beverage/图片7.png', title: 'Pok果肉粒蘋果汁', state:'none' },
    { src:'../../../../assets/products/beverage/图片8.png', title: '朝日 極 特濃牛奶咖啡', state:'none' },
  ];

  mouseIn(state, index){
      this.drinks[index].state = state;
  }

}
