import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {

  constructor(private router: Router) { }

  snackState = 'display';
  drinkState = 'display';
  cakeState = 'display';
  noodleState = 'display';
  candyState = 'display';
  wineState = 'display';
  giftingState = 'display';

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }

  snacks = [
    { source: '../assets/products/product-snack2.jpg' }, { source: '../assets/products/product-snack3.jpeg' },
    { source: '../assets/products/product-snack4.jpeg' }];

  drinks = [
    { source: '../assets/products/product-drink2.jpeg' }, { source: '../assets/products/product-drink3.jpeg' },];

  cakes = [
    { source: '../assets/products/product-cake2.jpeg' }, { source: '../assets/products/product-cake3.jpeg' },];

  noodles = [
    { source: '../assets/products/product-noodle2.jpeg' }];

  goTo(page) {
    if (page == 'snack') {
      this.router.navigate(['snack']);
    }
    else if(page == 'cake') {
      this.router.navigate(['cake']);
    }
    else if(page == 'drink') {
      this.router.navigate(['drink']);
    }
    else if(page == 'noodle') {
      this.router.navigate(['noodle']);
    }
    else if(page == 'candy') {
      this.router.navigate(['candy']);
    }
    else if(page == 'wine') {
      this.router.navigate(['wine']);
    }
    else if(page == 'gifting') {
      this.router.navigate(['gifting']);
    }
  };

  mouseIn(state, type) {
    if (type == 'snacks') {
      this.snackState = state;
    }
    else if (type == 'drinks') {
      this.drinkState = state;
    }
    else if (type == 'cakes') {
      this.cakeState = state;
    }
    else if (type == 'noodles') {
      this.noodleState = state;
    }
    else if (type == 'candy') {
      this.candyState = state;
    }
    else if (type == 'wine') {
      this.wineState = state;
    }
    else if (type == 'gifting') {
      this.giftingState = state;
    }
  };


}
