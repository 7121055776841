import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-snack',
  templateUrl: './snack.component.html',
  styleUrls: ['./snack.component.css']
})
export class SnackComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  snacks = [
    { src:'../../assets/products/snack/ORI 提子蒟蒻果凍者喱.png', title: 'ORI 提子蒟蒻果凍者喱',  state:'none'},
    { src:'../../assets/products/snack/Ori 青堤子味蒟蒻果凍者喱.png', title: 'Ori 青堤子味蒟蒻果凍者喱',  state:'none' },
    { src:'../../assets/products/snack/ORI 蜜桃味蒟蒻果凍者喱.png', title: 'ORI 蜜桃味蒟蒻果凍者喱',  state:'none' },
    { src:'../../assets/products/snack/Mam大片裝虾餅.jpg',  title: 'Mam大片裝虾餅', state:'none' },
    { src:'../../assets/products/snack/Hiz武平作蝦餅.png',  title: 'Hiz武平作蝦餅.png', state:'none' },
    { src:'../../assets/products/snack/Yei條裝蟹柳.png',  title: 'Yei條裝蟹柳', state:'none' },
    { src:'../../assets/products/snack/Neg 小丸蝦餅.png',  title: 'Neg 小丸蝦餅', state:'none' },
    { src:'../../assets/products/snack/Teg天狗橫綱紐紋脆條.png',  title: 'Teg天狗橫綱紐紋脆條', state:'none' },
    { src:'../../assets/products/snack/Teg天狗黑胡椒蝦條.png',  title: 'Teg天狗黑胡椒蝦條', state:'none' },
    { src:'../../assets/products/snack/Izu函館海鮮雜錦小食.png',  title: 'Izu函館海鮮雜錦小食', state:'none' },
    { src:'../../assets/products/snack/Hir 包裝小圓餅.png',  title: 'Hir 包裝小圓餅', state:'none' },
    { src:'../../assets/products/snack/Hon鐵火燒粟米湯米餅.png',  title: 'Hon鐵火燒粟米湯米餅', state:'none' },
    { src:'../../assets/products/snack/Eg 固力果綜合迷你甜筒餅乾.png',  title: 'Eg 固力果綜合迷你甜筒餅乾', state:'none' },
    { src:'../../assets/products/snack/DAI大包裝焼巨型帆立貝.png',  title: 'DAI大包裝焼巨型帆立貝',  state:'none' },
    { src:'../../assets/products/snack/Hth 藤田屋炭燒豬肉乾.png',  title: 'Hth 藤田屋炭燒豬肉乾', state:'none' },
    { src:'../../assets/products/snack/Hag 味付華印8P海苔.png',  title: 'Hag 味付華印8P海苔', state:'none' },
   
  ];

  mouseIn(state, index){
      this.snacks[index].state = state;
  }
  
}
