import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-candy',
  templateUrl: './candy.component.html',
  styleUrls: ['./candy.component.css']
})
export class CandyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }


  snacks = [
    { src:'../../assets/products/candy/kin 金城什錦水果味啫喱糖 190g.png', title: 'kin 金城什錦水果味啫喱糖', state:'none'},
    { src:'../../assets/products/candy/longjiao.jpg', title: '龍角散條裝糖', state:'none'},
    { src:'../../assets/products/candy/龍角散青檸味條裝糖.jpg', title: '龍角散青檸味條裝糖', state:'none'},
    { src:'../../assets/products/candy/龍角散蜂蜜味條裝糖.jpg', title: '龍角散蜂蜜味條裝糖', state:'none'},
    { src:'../../assets/products/candy/Heart 建設工場糖果 (玩食).jpg', title: 'Heart 建設工場糖果 (玩食)', state:'none'},
    { src:'../../assets/products/candy/Heart 髮型糖果 (玩食).jpg', title: 'Heart 髮型糖果 (玩食)', state:'none'},
    { src:'../../assets/products/candy/UHA Kororo 白桃軟糖.jpg', title: 'UHA Kororo 白桃軟糖', state:'none'},
    { src:'../../assets/products/candy/UHA Kororo 葡萄軟糖.jpg', title: 'UHA Kororo 葡萄軟糖', state:'none'},
    { src:'../../assets/products/candy/UHA Kororo 青提子軟糖.jpg', title: 'UHA Kororo 青提子軟糖', state:'none'},


  ];

  mouseIn(state, index){
      this.snacks[index].state = state;
  }
}
