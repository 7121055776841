import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cake',
  templateUrl: './cake.component.html',
  styleUrls: ['./cake.component.css']
})
export class CakeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }


  snacks = [
    { src:'../../assets/products/cake/Mar香蕉鬆餅2個裝.png', title: 'Mar香蕉鬆餅2個裝',  state:'none'},
    { src:'../../assets/products/cake/Mar 鬆餅原味蛋糕2個裝.png', title: 'Mar 鬆餅原味蛋糕2個裝',  state:'none' },
    { src:'../../assets/products/cake/Mki 厚切北海道牛乳蛋糕.png', title: 'Mki 厚切北海道牛乳蛋糕', state:'none' },
    { src:'../../assets/products/cake/丸金厚切巧克力蛋糕.png', title: '丸金厚切巧克力蛋糕', state:'none' },
    { src:'../../assets/products/cake/Mki 厚切芝士蛋糕.png', title: 'Mki 厚切芝士蛋糕', state:'none' },
    { src:'../../assets/products/cake/Mar 厚切抹茶年輪蛋糕.png', title: 'Mar 厚切抹茶年輪蛋糕',  state:'none' },
    { src:'../../assets/products/cake/Tan牛乳蛋糕.png', title: 'Tan牛乳蛋糕', state:'none' },
    { src:'../../assets/products/cake/Tan蜂蜜蛋糕.png', title: 'Tan蜂蜜蛋糕', state:'none' },
    { src:'../../assets/products/cake/Tan黒糖蛋糕.png', title: 'Tan黒糖蛋糕', state:'none' },
    { src:'../../assets/products/cake/Tan７個抹茶蛋糕.png', title: 'Tan７個抹茶蛋糕', state:'none' },
    { src:'../../assets/products/cake/Mik 北海道牛乳蛋糕仔.png', title: 'Mik 北海道牛乳蛋糕仔', state:'none' },
    { src:'../../assets/products/cake/Kot壽製果4個裝紅豆餅.png', title: 'Kot壽製果4個裝紅豆餅.png', state:'none' },
    { src:'../../assets/products/cake/Mak 牧原厚切年輪蛋糕.png', title: 'Mak 牧原厚切年輪蛋糕', state:'none' },
    { src:'../../assets/products/cake/Mar 長崎蛋糕.png', title: 'Mar 長崎蛋糕', state:'none' },
    { src:'../../assets/products/cake/mto 香蕉6個裝蛋糕.png', title: 'mto 香蕉6個裝蛋糕', state:'none' },
    { src:'../../assets/products/cake/Mto 布甸6個裝蛋糕.png', title: 'Mto 布甸6個裝蛋糕', state:'none' },

    
  ];

  mouseIn(state, index){
      this.snacks[index].state = state;
  }
}
