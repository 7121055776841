import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wine',
  templateUrl: './wine.component.html',
  styleUrls: ['./wine.component.css']
})
export class WineComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  wines = [
    { src:'../../../../assets/products/wine/Choya 1 年熟成梅酒 720ml.png', title: 'Choya 1 年熟成梅酒', state:'none'},
    { src:'../../../../assets/products/wine/Choya 宇治茶梅酒 720ml.png', title: 'Choya 宇治茶梅酒', state:'none' },
    { src:'../../../../assets/products/wine/Choya 微酒度梅酒 1000ml.png', title: 'Choya 微酒度梅酒', state:'none' },
    { src:'../../../../assets/products/wine/Choya 柚子酒 1000ml.png', title: 'Choya 柚子酒', state:'none' },
    { src:'../../../../assets/products/wine/Choya 極熟梅酒 720ml.png', title: 'Choya 極熟梅酒', state:'none' },
    { src:'../../../../assets/products/wine/Choya 熟成梅酒 720ml.png', title: 'Choya 熟成梅酒', state:'none' },
    { src:'../../../../assets/products/wine/Choya 至尊梅酒 720ml.png', title: 'Choya 至尊梅酒', state:'none' },
    { src:'../../../../assets/products/wine/Choya 至尊梅酒 禮盒裝.png', title: 'Choya 至尊梅酒 禮盒裝', state:'none' },
    { src:'../../../../assets/products/wine/Choya 黑糖梅酒 720ml.png', title: 'Choya 黑糖梅酒', state:'none' },
    { src:'../../../../assets/products/wine/Kirin 豊潤梅酒 撰 720ml.png', title: 'Kirin 豊潤梅酒 撰', state:'none' },
    { src:'../../../../assets/products/wine/Ozeki 大関 白鳳桃 梅酒 720ml.png', title: 'Ozeki 大関 白鳳桃 梅酒', state:'none' },
    { src:'../../../../assets/products/wine/Sapporo 白桃梅酒 500ml.png', title: 'Sapporo 白桃梅酒', state:'none' },
    { src:'../../../../assets/products/wine/Sapporo 西柚梅酒 500ml.png', title: 'Sapporo 西柚梅酒', state:'none' },
    { src:'../../../../assets/products/wine/Suntory 山崎蒸餾所貯蔵 焙煎樽熟成梅酒 750ml.png', title: 'Suntory 山崎蒸餾所貯蔵 焙煎樽熟成梅酒', state:'none' },
    { src:'../../../../assets/products/wine/Suntory 山崎蒸餾所貯藏威士忌烘焙樽釀梅酒 660ml.png', title: 'Suntory 山崎蒸餾所貯藏威士忌烘焙樽釀梅酒', state:'none' },
    { src:'../../../../assets/products/wine/Yomeishu 琥珀生姜酒 700ml.png', title: 'Yomeishu 琥珀生姜酒', state:'none' },
    { src:'../../../../assets/products/wine/Yomeishu 白桃杏仁草本水果酒 700ml.png', title: 'Yomeishu 白桃杏仁草本水果酒', state:'none' },
    { src:'../../../../assets/products/wine/Yomeishu 石榴玫瑰果草本水果酒 700ml.png',   title: 'Yomeishu 石榴玫瑰果草本水果酒', state:'none' },
    { src:'../../../../assets/products/wine/中田 桃姫梅酒 500ml.png', title: '中田 桃姫梅酒', state:'none' },
    { src:'../../../../assets/products/wine/國盛 無濾過梅酒 720ml.png', title: '國盛 無濾過梅酒',  state:'none' },
    { src:'../../../../assets/products/wine/國盛 玫瑰酒 720ml.png', title: '國盛 玫瑰酒',  state:'none' },
    { src:'../../../../assets/products/wine/江井 威士忌梅酒 (原酒).png', title: '江井 威士忌梅酒 (原酒)',  state:'none' },
    { src:'../../../../assets/products/wine/江井 白蘭地梅酒 (原酒).png', title: '江井 白蘭地梅酒 (原酒)',  state:'none' },
    { src:'../../../../assets/products/wine/白鶴 梅酒 (原酒) 720ml.png', title: '白鶴 梅酒 (原酒)', state:'none' },
    { src:'../../../../assets/products/wine/老松 梨園水果酒 500ml.png', title: '老松 梨園水果酒', state:'none' },
    { src:'../../../../assets/products/wine/賀茂鶴一滴入魂 純米吟醸 720ml.png', title: '賀茂鶴一滴入魂 純米吟醸',  state:'none' },
    { src:'../../../../assets/products/wine/金陵 柚子酒 500ml.png', title: '金陵 柚子酒', state:'none' },
    { src:'../../../../assets/products/wine/鍛高譚紫蘇梅酒 720ml.png', title: '鍛高譚紫蘇梅酒', state:'none' },
    { src:'../../../../assets/products/wine/高麗人参酒 700ml.png', title: '高麗人参酒', weight: '700ml', state:'none' },
    { src:'../../../../assets/products/wine/紀州梅酒 紅南高720g.png', title: '紀州梅酒 紅南高',  state:'none' },
    { src:'../../../../assets/products/wine/無添加梅酒 竜峡梅酒 14度 720ml.png', title: '無添加梅酒 竜峡梅酒 14度 720ml',  state:'none' },
    { src:'../../../../assets/products/wine/倉吉蒸餾所威士忌梅酒 700g.png', title: '倉吉蒸餾所威士忌梅酒',   state:'none' },
    { src:'../../../../assets/products/wine/倉吉蒸餾所白蘭地酒 700g.png', title: '倉吉蒸餾所白蘭地酒',   state:'none' },
    { src:'../../../../assets/products/wine/Sun 三得利桃味酒.jpg', title: 'Sun 三得利桃味酒',   state:'none' },
    { src:'../../../../assets/products/wine/Sun 三得利葡萄酸.jpg', title: '倉吉蒸餾所白蘭地酒',   state:'none' },
    { src:'../../../../assets/products/wine/Sun 三得利 微醉酒〈白葡萄.jpg', title: 'Sun 三得利 微醉酒〈白葡萄',   state:'none' },
    { src:'../../../../assets/products/wine/－196℃ 強零〈雙重扁實檸檬〉.jpg', title: '－196℃ 強零〈雙重扁實檸檬〉',   state:'none' },
    { src:'../../../../assets/products/wine/sun三得利 無糖無卡路利 啤酒.jpg', title: 'sun三得利 無糖無卡路利 啤酒',   state:'none' },
    { src:'../../../../assets/products/wine/sap　極ＺＥＲＯ啤酒.jpg', title: 'sap　極ＺＥＲＯ啤酒',   state:'none' },
    { src:'../../../../assets/products/wine/KIR 麒麟淡麗啤酒白金罐.jpg', title: 'KIR 麒麟淡麗啤酒白金罐',   state:'none' },
    { src:'../../../../assets/products/wine/Asahi朝日 乾零 350ml.jpg', title: 'Asahi朝日 乾零',   state:'none' },
    { src:'../../../../assets/products/wine/Kir 麒麟低麥芽啤酒 350ml.jpg', title: 'Kir 麒麟低麥芽啤酒',   state:'none' },
    { src:'../../../../assets/products/wine/sun 三得利 jim beam 微酒 (檸檬味).jpg', title: 'sun 三得利 jim beam 微酒 (檸檬味)',   state:'none' },
    { src:'../../../../assets/products/wine/Sun 三海波清新檸檬味微酒.jpg', title: 'Sun 三海波清新檸檬味微酒',   state:'none' },
    { src:'../../../../assets/products/wine/三得利海波〈濃黑微酒〉.jpg', title: '三得利海波〈濃黑微酒〉',   state:'none' },
    { src:'../../../../assets/products/wine/Sun 三得利海波泡沫蘇打微酒.jpg', title: 'Sun 三得利海波泡沫蘇打微酒',   state:'none' },
    { src:'../../../../assets/products/wine/Kir 麒麟冰結®郝志荔枝.jpg', title: 'Kir 麒麟冰結®郝志荔枝',   state:'none' },
    { src:'../../../../assets/products/wine/Kir 麒麟冰結®菠蘿.jpg', title: 'Kir 麒麟冰結®菠蘿',   state:'none' },
    { src:'../../../../assets/products/wine/Nodouma 樂多生啤.png', title: 'Nodouma 樂多生啤',   state:'none' },
    
    { src:'../../../../assets/products/wine/白鶴酒造 梅酒原酒.png', title: '白鶴酒造 梅酒原酒',   state:'none' },
    { src:'../../../../assets/products/wine/白瀧 熟成 上善如水.png', title: '白瀧 熟成 上善如水',   state:'none' },
    { src:'../../../../assets/products/wine/武藏野純米酒.png', title: '武藏野純米酒',   state:'none' },

    { src:'../../../../assets/products/wine/武藏野生貯藏酒.png', title: '武藏野生貯藏酒',   state:'none' },
    { src:'../../../../assets/products/wine/武藏野日本酒.png', title: '武藏野日本酒',   state:'none' },
    { src:'../../../../assets/products/wine/武藏野大吟釀.png', title: '武藏野大吟釀',   state:'none' },
    { src:'../../../../assets/products/wine/梅乃宿 細果粒桃酒.png', title: '梅乃宿 細果粒桃酒',   state:'none' },
    { src:'../../../../assets/products/wine/梅乃宿 柚子酒.png', title: '梅乃宿 柚子酒',   state:'none' },
    { src:'../../../../assets/products/wine/本坊酒造 上等知覧茶梅酒.png', title: '本坊酒造 上等知覧茶梅酒',   state:'none' },
    { src:'../../../../assets/products/wine/中野釀造玫瑰梅酒.png', title: '中野釀造玫瑰梅酒',   state:'none' },
    { src:'../../../../assets/products/wine/Shi 白瀧 上善如水.png', title: 'Shi 白瀧 上善如水',   state:'none' },
    { src:'../../../../assets/products/wine/Oim 老松酒造 梨園 梨味.png', title: 'Oim 老松酒造 梨園 梨味',   state:'none' },
    { src:'../../../../assets/products/wine/Nis 西山小鼓 桃花.png', title: 'Nis 西山小鼓 桃花',   state:'none' },
    { src:'../../../../assets/products/wine/Nak 中田桃姖梅酒.png', title: 'Nak 中田桃姖梅酒',   state:'none' },
    { src:'../../../../assets/products/wine/Kob 新加賀梅酒.png', title: 'Kob 新加賀梅酒',   state:'none' },
    { src:'../../../../assets/products/wine/Choya 梅酒 單年.png', title: 'Choya 梅酒 單年',   state:'none' },
    { src:'../../../../assets/products/wine/Choya The BLACK 芳醇白蘭地梅酒.png', title: 'Choya The BLACK 芳醇白蘭地梅酒',   state:'none' },
    
    
  ];

  mouseIn(state, index){
    this.wines[index].state = state;
  }

}
