import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LanguageService } from './language.service';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';

import { HomeComponent } from "./home/home.component";
import { AboutComponent } from './about/about.component';
// import { ClientComponent } from './client/client.component';
import { ProductComponent } from './product/product.component';
import { SnackComponent } from './snack/snack.component';
import { DrinkComponent } from './drink/drink.component';
import { CakeComponent } from './cake/cake.component';
import { NoodleComponent } from './noodle/noodle.component';
import { CandyComponent } from './candy/candy.component';
import { WineComponent } from './wine/wine.component';
import { GiftingComponent } from './gifting/gifting.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { trigger, state, style } from '@angular/animations';

import {FormsModule} from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { ChocolateComponent } from './chocolate/chocolate.component';
import { ThailandComponent } from './thailand/thailand.component';

const appRoutes: Routes = [

  // { path: 'home', component: HomeComponent },
  { path: '', component: AboutComponent },
  { path: 'about', component: AboutComponent },
  // { path: 'client', component: ClientComponent },
  { path: 'product', component: ProductComponent},
  { path: 'snack', component: SnackComponent},
  { path: 'cake', component: CakeComponent},
  { path: 'candy', component: CandyComponent},
  { path: 'wine', component: WineComponent},
  { path: 'gifting', component: GiftingComponent},
  { path: 'drink', component: DrinkComponent},
  { path: 'noodle', component: NoodleComponent},
  { path: 'chocolate', component: ChocolateComponent},
  { path: 'thailand', component: ThailandComponent},
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    // ClientComponent,
    ProductComponent,
    SnackComponent,
    DrinkComponent,
    CakeComponent,
    NoodleComponent,
    CandyComponent,
    WineComponent,
    GiftingComponent,
    ChocolateComponent,
    ThailandComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes),
    BrowserAnimationsModule,
    FormsModule
  ],
  providers: [LanguageService,CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }
