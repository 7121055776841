import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { HostListener, ElementRef, ViewChild } from '@angular/core';
import { LanguageService } from '../language.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css'],
  animations: [
    trigger('animation-left', [
      state('show', style({
        opacity: 1,
        transform: "translateX(0)"
      })),
      state('hide', style({
        opacity: 0,
        transform: "translateX(-10%)"
      })),
      transition('hide <=> show', animate(700))
    ]),
    trigger('animation-right', [
      state('show', style({
        opacity: 1,
        transform: "translateX(0)"
      })),
      state('hide', style({
        opacity: 0,
        transform: "translateX(10%)"
      })),
      transition('hide <=> show', animate(700))
    ]),
    trigger('story-animation-text', [
      state('show', style({
        opacity: 1,
        transform: "translateY(0)"
      })),
      state('hide', style({
        opacity: 0,
        transform: "translateY(10%)"
      })),
      transition('hide <=> show', animate(700))
    ]),
    trigger('story-animation-img', [
      state('show', style({
        opacity: 1,
        transform: "translateY(0)"
      })),
      state('hide', style({
        opacity: 0,
        transform: "translateY(10%)"
      })),
      transition('hide <=> show', animate(700))
    ])
  ]
})
export class AboutComponent implements OnInit {

  missionTextState = 'hide';
  missionImgState = 'hide';
  storyTextState = 'hide';
  storyImgState = 'hide';

  constructor(private router: Router, public languageService: LanguageService) {   }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    // set timer to show animation on init 
    if (this.storyTextState == 'hide') {
      setTimeout(() => this.storyTextState = 'show')
    }
    if (this.storyImgState == 'hide') {
      setTimeout(() => this.storyImgState = 'show', 500)
    }
  }

  @ViewChild('missionAnimation') missionAnimation: ElementRef;
  @ViewChild('storyAnimation') storyAnimation: ElementRef;

  @HostListener('window:scroll')
  scrolled() {
    let missionPosition = this.missionAnimation.nativeElement.offsetTop;

    let scrollPosition = (window.pageYOffset + 600);

    if (scrollPosition > missionPosition) {
      this.missionTextState = 'show';
      setTimeout(() => this.missionImgState = 'show', 500)
    }
  }

}
