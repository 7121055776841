import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-thailand',
  templateUrl: './thailand.component.html',
  styleUrls: ['./thailand.component.css']
})
export class ThailandComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  snacks = [
    { src:'../../assets/products/thailand/图片1.png', title: 'Bento 超味魷(藍)',  state:'none'},
    { src:'../../assets/products/thailand/图片2.png', title: 'Bento 超味魷(紅)',  state:'none' },
    { src:'../../assets/products/thailand/图片3.png', title: 'Bento 超味魷(橙)', state:'none' },
    { src:'../../assets/products/thailand/图片4.png', title: 'Bento 超味魷(藍)', state:'none' },
    { src:'../../assets/products/thailand/图片5.png', title: 'Bento 超味魷(紅)', state:'none' },
    { src:'../../assets/products/thailand/图片6.png', title: 'Bento 超味魷(橙)',  state:'none'},
    { src:'../../assets/products/thailand/图片7.png', title: '阿華田曲奇',  state:'none' },
    { src:'../../assets/products/thailand/图片8.png', title: '麥維他消化餅', state:'none' },
    { src:'../../assets/products/thailand/图片9.png', title: '百力滋餠乾條 (粟米味)', state:'none' },
    { src:'../../assets/products/thailand/图片10.png', title: '百力滋餠乾條 (冬陰公味)',  state:'none'},
    { src:'../../assets/products/thailand/图片11.png', title: '百力滋餠乾條 (辛辣燒烤味)',  state:'none' },
    { src:'../../assets/products/thailand/图片12.png', title: '百力滋餠乾條 (芝士味)', state:'none' },
    { src:'../../assets/products/thailand/图片13.png', title: '百力滋餠乾條 (辛辣海蝦味)', state:'none' },
    { src:'../../assets/products/thailand/图片14.png', title: '小老闆紫菜(日本醬油味)', state:'none' },
    { src:'../../assets/products/thailand/图片15.png', title: '小老闆紫菜(辣味)',  state:'none'},
    { src:'../../assets/products/thailand/图片16.png', title: '小老闆紫菜(海鮮味)',  state:'none' },
    { src:'../../assets/products/thailand/图片17.png', title: '小老闆紫菜(原味)', state:'none' },
    { src:'../../assets/products/thailand/图片18.png', title: '小老闆紫菜(冬陰公味)', state:'none' },
    { src:'../../assets/products/thailand/图片19.png', title: '小老闆紫菜捲(燒烤味)', state:'none' },
    { src:'../../assets/products/thailand/图片20.png', title: '小老闆紫菜捲(原味)', state:'none' },
    { src:'../../assets/products/thailand/图片21.png', title: '小老闆紫菜捲(辣味)',  state:'none'},
    { src:'../../assets/products/thailand/图片22.png', title: '小老闆紫菜捲(魷魚味)',  state:'none' },
    { src:'../../assets/products/thailand/图片23.png', title: 'FF 蕃茄片', state:'none' },
    { src:'../../assets/products/thailand/图片24.png', title: '紅牛 能量飲料', state:'none' },
    { src:'../../assets/products/thailand/图片25.png', title: '日清杯麵 (冬陰公味)', state:'none' },
    { src:'../../assets/products/thailand/图片26.png', title: '日清杯麵',  state:'none'},
    { src:'../../assets/products/thailand/图片27.png', title: '日清杯麵 (忌廉海鮮湯)',  state:'none' },
    { src:'../../assets/products/thailand/图片28.png', title: '玉泉青檸梳打', state:'none' },
    { src:'../../assets/products/thailand/图片29.png', title: '可口可樂 (咖啡味)', state:'none' },
    { src:'../../assets/products/thailand/图片30.png', title: '泰式 梳打',  state:'none'},
    { src:'../../assets/products/thailand/图片31.png', title: 'DEHYDRATED 火龍果果乾',  state:'none' },
    { src:'../../assets/products/thailand/图片32.png', title: 'DEHYDRATED 芒果乾', state:'none' },
    { src:'../../assets/products/thailand/图片33.png', title: 'DEHYDRATED 士多啤梨乾', state:'none' },
    { src:'../../assets/products/thailand/图片34.png', title: '恐龍谷 餠乾', state:'none' },
    { src:'../../assets/products/thailand/图片35.png', title: '恐龍谷 餠乾 - 海鮮味',  state:'none'},
    { src:'../../assets/products/thailand/图片36.png', title: '介子糖',  state:'none' },
    { src:'../../assets/products/thailand/图片37.png', title: '無花果乾 樽裝', state:'none' },

    
  ];

  mouseIn(state, index){
      this.snacks[index].state = state;
  }
}
