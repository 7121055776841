import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { HostListener, ElementRef, ViewChild } from '@angular/core';
import { LanguageService } from '../language.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [
    trigger('animation-btn', [
      state('show', style({
        opacity: 1,
        transform: "translateX(0)"
      })),
      state('hide', style({
        opacity: 0,
        transform: "translateX(50%)"
      })),
      transition('hide <=> show', animate(900))
    ]),
    trigger('animation-product', [
      state('show', style({
        opacity: 1,
        transform: "translateY(0)"
      })),
      state('hide', style({
        opacity: 0,
        transform: "translateY(10%)"
      })),
      transition('hide <=> show', animate(800))
    ])
  ]
})
export class HomeComponent implements OnInit {

  carouselItems = [
    { source: '../assets/slide2.jpeg' }, { source: '../assets/slide3.jpeg' },
    { source: '../assets/slide4.jpeg' },
  ];

  constructor(private router: Router, public languageService: LanguageService) {
  }

  // ulAnimationState = 'hide';
  // btnAnimationState = 'hide';
  firstRowState = 'hide';
  secondRowState = 'hide';
  thirdRowState = 'hide';
  // fourthRowState = 'hide';

  // @ViewChild('btnAnimation') btnAnimation: ElementRef;
  @ViewChild('firstRow') firstRow: ElementRef;
  @ViewChild('secondRow') secondRow: ElementRef;
  @ViewChild('thirdRow') thirdRow: ElementRef;
  // @ViewChild('fourthRow') fourthRow: ElementRef;

  @HostListener('window:scroll')
  scrolled() {
    // let btnPosition = this.btnAnimation.nativeElement.offsetTop;

    let firstRowPosition = this.firstRow.nativeElement.offsetTop;
    let secondRowPosition = this.secondRow.nativeElement.offsetTop;
    let thirdRowPosition = this.thirdRow.nativeElement.offsetTop;
    // let fourthRowPosition = this.fourthRow.nativeElement.offsetTop;
    

    let scrollPosition = (window.pageYOffset + 600);

    // if (scrollPosition > btnPosition) {
    //   this.btnAnimationState = 'show';
    // }
    if (scrollPosition > firstRowPosition) {
      this.firstRowState = 'show';
    }
    if (scrollPosition > secondRowPosition) {
      this.secondRowState = 'show';
    }
    if(scrollPosition > thirdRowPosition){
      this.thirdRowState = 'show';
    }
    // if(scrollPosition > fourthRowPosition){
    //   this.fourthRowState = 'show';
    // }
  }


  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });

  };

  toAbout() {
    this.router.navigate(['about']);
  }

  // products

  snacks = [
    { source: '../assets/products/product-snack2.jpg' }, { source: '../assets/products/product-snack3.jpeg' },
    { source: '../assets/products/product-snack4.jpeg' }];

  drinks = [
    { source: '../assets/products/beverage/Ucc混合微糖咖啡185ml.jpg' }, { source: '../assets/products/beverage/Nescafe雀巢黃金混合咖啡930ml.jpg' },
    { source: '../assets/products/beverage/Ucc職人珈琲無糖930ml.jpg' },{ source: '../assets/products/beverage/JA優酪乳酸飲料.jpg' },];

  cakes = [
    { source: '../assets/products/product-cake2.jpeg' }, { source: '../assets/products/product-cake3.jpeg' },];

  noodles = [
    { source: '../assets/products/product-noodle2.jpeg' }];

  wines = [
      { source: '../assets/products/wine/Choya 至尊梅酒 720ml.png' },{ source: '../assets/products/wine/Choya 1 年熟成梅酒 720ml.png' },
      { source: '../assets/products/wine/Yomeishu 琥珀生姜酒 700ml.png' }];


  goTo(page) {
    if (page == 'snack') {
      this.router.navigate(['snack']);
    }
    else if(page == 'cake') {
      this.router.navigate(['cake']);
    }
    else if(page == 'drink') {
      this.router.navigate(['drink']);
    }
    else if(page == 'noodle') {
      this.router.navigate(['noodle']);
    }
    else if(page == 'candy') {
      this.router.navigate(['candy']);
    }
    else if(page == 'wine') {
      this.router.navigate(['wine']);
    }
    else if(page == 'gifting') {
      this.router.navigate(['gifting']);
    }
  };


}
