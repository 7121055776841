import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-noodle',
  templateUrl: './noodle.component.html',
  styleUrls: ['./noodle.component.css']
})
export class NoodleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  snacks = [
    { src:'../../assets/products/noodle/SAN SAPPORO 豚骨麵 ５包裝.png',  title: 'SAN SAPPORO 豚骨麵 ５包裝',  state:'none'},
    { src:'../../assets/products/noodle/SAP一番 塩豚骨拉麵 5包裝.png',  title: 'SAP一番 塩豚骨拉麵 5包裝',  state:'none' },
    { src:'../../assets/products/noodle/Chd 千鳥丸龜禶岐烏東.png', title: 'Chd 千鳥丸龜禶岐烏東',  state:'none' },
    { src:'../../assets/products/noodle/Hag 快熟意大利粉.png', title: 'Hag 快熟意大利粉', state:'none' },
    { src:'../../assets/products/noodle/Hag 筆管粉.png', title: 'Hag 筆管粉', state:'none' },
    { src:'../../assets/products/noodle/Hag 1.6mm 意大利粉.png', title: 'Hag 1.6mm 意大利粉', state:'none' },
    { src:'../../assets/products/noodle/Hag 1.4mm 意大利粉.png', title: 'Hag 1.4mm 意大利粉', state:'none' },
    { src:'../../assets/products/noodle/Hag 低糖質意大利粉.png', title: 'Hag 低糖質意大利粉', state:'none' },
    { src:'../../assets/products/noodle/Hag 低糖質通粉.png', title: 'Hag 低糖質通粉', state:'none' },
    { src:'../../assets/products/noodle/Hag 低糖質螺絲粉.png', title: 'Hag 低糖質螺絲粉', state:'none' },
    { src:'../../assets/products/noodle/Hag 罐頭蛤蜊蔬菜意粉醬.png', title: 'Hag 罐頭蛤蜊蔬菜意粉醬', state:'none' },
    { src:'../../assets/products/noodle/Hag 罐頭蛤蜊蔬菜番茄奶油意粉醬.png', title: 'Hag 罐頭蛤蜊蔬菜番茄奶油意粉醬', state:'none' },
  


  
  ];

  mouseIn(state, index){
      this.snacks[index].state = state;
  }

}
